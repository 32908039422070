import { Directive, ElementRef, HostListener, Input } from '@angular/core';

/**
 * A directive that prevents the input of spaces into an input field.
 * It also provides options to capitalize or lowercase the input.
 */
@Directive({
  selector: '[appNoSpaces]'
})
export class NoSpacesDirective {
  /**
   * The options to apply to the input.
   * 'nospace' to prevent spaces from being entered,
   * 'capital' to capitalize the input,
   * 'small' to lowercase the input.
   */
  @Input('appNoSpaces') options = '';

  constructor(private el: ElementRef) { }
  /**
   * Listens for input events and applies the options to the input.
   * @param event The input event.
   */
  @HostListener('input', ['$event']) onInput(event: KeyboardEvent): void {
    const inputValue = this.el.nativeElement.value;
    let modifiedValue = inputValue;
    if (this.options.includes('nospace')) {
      modifiedValue = inputValue.replace(/\s/g, '');
    }
    if (this.options.includes('capital')) {
      modifiedValue = inputValue.toUpperCase();
    }
    if (this.options.includes('small')) {
      modifiedValue = inputValue.toLowerCase();
    }
    this.el.nativeElement.value = modifiedValue;
    event.preventDefault();
  }
}

