import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { AuthenticationService } from '../../../core/services/authentication.service';
import { TicketServiceService } from '../../../modules/profile/ticketService.service';
import { CommonService } from '../../services/common.service';

@Component({
  selector: 'app-training-videos',
  templateUrl: './training-videos.component.html',
})
export class TrainingVideosComponent implements OnInit {

  @ViewChild('viewTicketModal', { static: true })
  viewResourceModal!: TemplateRef<any>;
  isLoading: boolean = false;
  listOfData: any[] = [];
  data: any[] = [];
  resources: any= null;
  isTableLoading:boolean = false;

  constructor(
    protected _location: Location,
    public auth: AuthenticationService,
    private ticketService: TicketServiceService,
    public commonSvc: CommonService,
  ) { }

  ngOnInit(): void {
    this.getDemoVideos();
  }

  /**
   * Get demo videos from the API
   */
  getDemoVideos(): void {
    this.isTableLoading = true;
    this.ticketService.getDemoVideo().subscribe({
      next: (response) => {
        if (response && response.data) {
          this.listOfData = response.data.map((item: any) => ({
            serialNumber: item.serialnumber,
            title: item.title,
            description: item.description,
            fileLocation: item.fileLocation
          }));
          this.isTableLoading = false;
        }
      },
      error: (err) => {
        console.error('Error fetching demo videos:', err);
        this.isTableLoading = false;
      }
    });
  }

  /**
   * Opens a modal with the details of the selected resource.
   * @param data the selected resource
   * @param tplContent the template of the modal content
   */
  protected viewTicket(data: any, tplContent: TemplateRef<any>): void {
    this.getDemoVideos();
    this.resources = data;
    this.commonSvc.modalSvc.create({
      nzTitle: 'Title: ' + this.resources.title,
      nzContent: tplContent,
      nzData: this.resources,
      nzWidth: '70%',
      nzCloseOnNavigation: true,
      nzFooter: null,
      nzClosable: true,
      nzMaskClosable: false,
    });
  }
}


