import { AuthenticationService } from './../../../core/services/authentication.service';
import { Component, TemplateRef, ElementRef, ViewChild, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { CommonService } from '../../../shared/services/common.service';
import { TicketServiceService } from '../ticketService.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { distinctUntilChanged, filter, forkJoin } from 'rxjs';
import { selectLocation } from '../../location-tree/state/location-tree.state';

export type TicketStatusType = 'open' | 'in-progress' | 'resolved' | 'closed';

export interface Ticket {
  subject: string;
  ticket_id: number;
  user_subject: string;
  status: TicketStatusType;
  description: string;
  user_description: string;
  user_id?: number;
  customer_id?: number;
  email?: string;
  role_id?: number;
  user_name?: string;
  location_id?: number;
  customer_name?: string;
  fileLocation?: string;
}
@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-get-support',
  templateUrl: './get-support.component.html',
  styleUrl: './get-support.component.scss',
})

export class GetSupportComponent implements OnInit {
  @ViewChild('supportFile', { static: false }) supportFile!: ElementRef;
  @ViewChild('viewTicketModal', { static: false })
  viewTicketModal!: TemplateRef<any>;
  value: any[] = [];
  nodes: any[] = [];
  location_id!: any;
  isLocationError: boolean = false;
  fileData: any[] = [];
  fileList: File[] = [];
  location_ids?: any[] = [];
  maxFileSize = 5 * 1024 * 1024;
  maxFiles = 3;
  formSubmitted: boolean = false;
  isLoading: boolean = false;
  isDataValid = false;
  ticketForm!: FormGroup;
  quickFilter: string = 'all';
  recentTickets: any[] = [];
  filters: Array<any> = [
    {
      value: 'all',
      class: 'font-semibold radio-button-primary',
      icon: 'unordered-list',
      label: 'All',
    },
    {
      value: '-1',
      class: 'font-semibold radio-button-success',
      icon: 'check-circle',
      label: 'Closed',
    },
    {
      value: '1',
      class: 'font-semibold radio-button-danger',
      icon: 'close-circle',
      label: 'Open',
    },
  ];
  customer_id?: number;
  timezone: string = '';
  ticketList: any[] = [];
  ticketById: { subject: string; description: string; fileLocation: string } = {
    subject: '',
    description: '',
    fileLocation: '',
  };
  fullText = this.ticketById.description;
  truncatedText = this.fullText.substring(0, 50);
  isExpanded = false;
  parsedFileLocations: string[] = [];

  constructor(
    protected _location: Location,
    private fb: FormBuilder,
    private message: NzMessageService,
    private commonSvc: CommonService,
    private ticketService: TicketServiceService,
    public auth: AuthenticationService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.initializeForm();
    this.ticketList = this.recentTickets;
    if(this.auth.isAuthenticated() === true){
      this.commonSvc.store.select(selectLocation)
              .pipe(
                filter(location => location !== null),
                distinctUntilChanged(),
                untilDestroyed(this)
              ).subscribe(() => {
                this.getRecentTicket()
              });
            const isInit = localStorage.getItem('INIT') === 'true';
            if (isInit) { this.getRecentTicket() }
    }
    let location_payload = {
          userId: this.commonSvc.user_data.user_id,
          email: this.commonSvc.user_data.email,
          customerId: this.commonSvc?.user_data.customer?.customer_id,
          locationId: this.commonSvc.assignedLocations
        }
        forkJoin([this.commonSvc.fetchAccessLocationTree(location_payload)])
          .pipe(
            untilDestroyed(this),
            filter((value) => !!value)
          ).subscribe({
            next: ([locationData]) => {
              if (locationData?.status === 200) this.nodes = this.commonSvc.convertToNzTreeSelectFormat(locationData?.data);
              console.log('locationData', locationData);
            },
            error: (err) => { },
            complete: () => {
              if (this.nodes?.[0]) {
                this.nodes[0].disabled = true;
              }
            },
        });


  }

  /**
   * Quick filter change event handler.
   * Filters the ticket list based on the filter event.
   * @param event - the filter event
   */
  quickFilterChange(event: any) {
    this.ticketList = this.recentTickets.filter((ticket) => {
      if (event === 'all') {
        return ticket;
      }
      if (event === '-1') {
        return ticket.status === 'closed';
      }
      if (event === '1') {
        return ticket.status === 'open';
      }
    });
  }
  /**
   * Initialize the ticket form.
   * Based on the authentication status, the form controls are added or removed.
   */
  initializeForm() {
    if (this.auth.isAuthenticated() === false) {
      this.ticketForm = this.fb.group({
        customer_id: ['', [Validators.required]],
        user_name: ['', [Validators.required]],
        email: ['', [Validators.required, Validators.email]],
        subject: ['', [Validators.required]],
        description: ['', [Validators.required, Validators.maxLength(500)]],
      });
    } else {
      this.ticketForm = this.fb.group({
        user_subject: ['', [Validators.required]],
        user_description: ['', [Validators.required, Validators.maxLength(500)]],
      });
    }
  }
  /**
   * Handles the file change event.
   * @param event The file change event
   */
  onFileChange(event: any): void {
    const file = event.target.files;
    const newFiles = Array.from(file);
    if (this.fileList.length + newFiles.length > this.maxFiles) {
      this.message.error(
        `You can only upload a maximum of ${this.maxFiles} files`
      );
      return;
    }
    for (const file of newFiles as File[]) {
      if (file.size > this.maxFileSize) {
        this.message.error(`File ${file.name} exceeds 5MB limit`);
        continue;
      }
      const fileName = file.name.toLowerCase();
      const validExtensions = ['.pdf', '.jpeg', '.jpg', '.png', '.mp4'];
      const isValidType = validExtensions.some((ext) => fileName.endsWith(ext));
      if (!isValidType) {
        this.message.error(`File ${file.name} is not an allowed file type. Please upload pdf, jpeg, png or mp4 files only.`);
        continue;
      }
      this.fileList.push(file);
    }
  }
  /**
   * Removes a file from the file list at the specified index.
   * @param index - The index of the file to remove.
   */
  removeFile(index: number): void {
    this.fileList.splice(index, 1);
    this.supportFile.nativeElement.value = '';
  }
  /**
   * Gets the color associated with the specified ticket status.
   * @param status The ticket status.
   * @returns The color associated with the ticket status.
   */
  getStatusColor(status: TicketStatusType): string {
    /**
     * Colors associated with each ticket status.
     * - open: error
     * - in-progress: warning
     * - resolved: success
     * - closed: success
     */
    const colors = {
      open: 'error',
      'in-progress': 'warning',
      resolved: 'success',
      closed: 'success',
    };
    return colors[status] || 'default';
  }
  /**
   * Resets the ticket form.
   * - Resets the form controls to their default values.
   * - Clears the list of files to upload.
   * - Displays an information message indicating that the form has been reset.
   * - If the user is logged in, resets the file input element value.
   */
  resetForm() {
    this.ticketForm.reset();
    this.fileList = [];
    this.message.info('Form has been reset');
    if (this.auth.isAuthenticated() === true) {
      this.supportFile.nativeElement.value = '';
    }
   this.isLoading = false;
  }

  trainingVideos() {
    this.router.navigate(['/training-videos']);
  }

  onLocationChange(event:string)
  {
    this.location_id = Number(event);
  }

  /**
   * Submits the ticket form.
   * - If the user is not logged in, logs the ticket as a guest ticket.
   * - If the user is logged in, logs the ticket under the user's account.
   * - If the submission is successful, displays a success message and resets the form.
   * - If the submission fails, displays an error message.
   */
  onSubmitTicket() {
    this.formSubmitted = true;
    this.isLoading = true;
    if (this.auth.isAuthenticated() === false) {
      if (this.commonSvc.validate(this.ticketForm)) {
        const payload = {
          Login_Ticket: {
            ...this.ticketForm.getRawValue(),
            status: 'open',
          }
        };
        console.log(payload);

        this.ticketService
          .addSupportTicket(payload)
          .pipe(untilDestroyed(this))
          .subscribe({
            next: (response: any) => {
              if (response.status === 200) {
                this.commonSvc.modalSvc.success({
                  nzTitle: 'Success',
                  nzContent: `Your ticket has been submitted successfully!
                   Ticket ID: ${response.ticket_id}`,
                  nzOnOk: () => {},
                });
                this.ticketForm.reset();
              } else {
                this.message.error('Failed to submit Ticket');
              }
              this.isLoading = false;
            },
            error: (err) => this.isLoading = false,
          });
      }
    } else {
      if (this.commonSvc.validate(this.ticketForm)) {
        const newTicket = {
          ...this.ticketForm.value,
          user_status: 'open',
          user_id: this.commonSvc.user_data.user_id,
          user_customer_id: this.commonSvc.user_data.customer.customer_id,
          customer_name: this.commonSvc.user_data.customer.customer_name,
          user_email: this.commonSvc.user_data.email,
          role_id: this.commonSvc.user_data.userRoles.role_id,
          user_name: this.commonSvc.user_name,
          location_id: this.location_id,
        };
        const formData = new FormData();
        formData.append('Ticket', JSON.stringify(newTicket));
        this.fileList.forEach((value:any) => {
          formData.append('files', value, value.name);
        });
        this.ticketService
          .addSupportTicket(formData)
          .pipe(untilDestroyed(this))
          .subscribe({
            next: (response: any) => {
              if (response.status === 200) {
                this.commonSvc.modalSvc.success({
                  nzTitle: 'Success',
                  nzContent: `Your ticket has been submitted successfully!
                   Ticket ID:  ${response.ticket_id}`,
                  nzOnOk: () => {},
                });
                this.getRecentTicket();
                this.ticketForm.reset();
                this.fileList = [];
              }
              else if(response.status === 406) {
                const infectedFiles = response.error.infectedFiles;
                const infectedFilesList = infectedFiles
                  .map((file: any) => `<li>${file.name} - Viruses: ${file.viruses.join(', ')}</li>`)
                  .join('');
                  this.commonSvc.modalSvc.error({
                  nzTitle: 'Virus Detected in Uploaded Files',
                  nzContent: `
                    <p>The following files are infected and cannot be uploaded:</p>
                    <ul>${infectedFilesList}</ul>
                  `,
                  nzOnOk: () => {},
                });
              }
              else {
                this.message.error('Failed to submit Ticket');
              }
              this.isLoading = false;
            },
            error: (err) => this.isLoading = false,
          });
      }
    }
  }
  isTableLoading:boolean = true;
  getRecentTicket() {
    this.isTableLoading = true;
    this.ticketList = [];
    this.ticketService.fetchRecentTicket({
      customer_id: this.commonSvc.user_data.customer.customer_id,
      location_id: this.commonSvc.nLevelPayload['location_id'],
      timezone: this.commonSvc.nLevelPayload['timezone']
    }).pipe(untilDestroyed(this)).subscribe({
      next: (response: any) => {
        if (response.status == 200 && response.data.length > 0) {
          console.log('response', response);
          this.recentTickets = response.data;
          this.ticketList = this.recentTickets;
        }
        this.isTableLoading = false;
      },
      error: () => this.isLoading = false
    });
  }



  toggleText(): void {
    this.isExpanded = !this.isExpanded;
  }
  /**
   * Displays the ticket details in a modal.
   * @param ticket The ticket object containing details to view.
   */
  protected viewTicket(ticket: any): void {
    this.ticketById = ticket;
    this.parsedFileLocations = JSON.parse(ticket.fileLocation[0] == null ? '[]' : ticket.fileLocation);
    this.getRecentTicket();
    this.commonSvc.modalSvc.create({
      nzTitle: 'Ticket Details for Ticket ID: ' + ticket.ticket_id,
      nzContent: this.viewTicketModal,
      nzFooter: null,
      nzClosable: true,
    });
  }
  /**
   * Opens the attachment in a new tab.
   * @param fileUrl The URL of the attachment to open.
   */
  protected openAttachment(fileUrl: string): void {
    if (fileUrl) {
      const a: any = document.createElement('a');
      a.href = fileUrl;
      a.target = '_blank';
      a.download = '';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  }
}
