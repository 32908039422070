import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { CommonService } from '../../../shared/services/common.service';
import { UsersService } from '../../admin/user-management/users.service';
import { Store } from '@ngrx/store';
import { AuthState } from '../../auth/state/auth.state';
import { updateName, updatePref } from '../../auth/state/auth.actions';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { finalize } from 'rxjs';
import { AuthService } from '../../auth/auth.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
interface TimeZone {
  name: string;
  value: string;
}
export const timezones: TimeZone[] = [
  { name: "Pacific Standard Time (PST)", value: "America/Los_Angeles" },
  { name: "Mountain Standard Time (MST)", value: "America/Denver" },
  { name: "Central Standard Time (CST)", value: "America/Chicago" },
  { name: "Eastern Standard Time (EST)", value: "America/New_York" },
  { name: "Greenwich Mean Time (GMT)", value: "Europe/London" },
  { name: "Central European Time (CET)", value: "Europe/Berlin" },
  { name: "India Standard Time (IST)", value: "Asia/Kolkata" },
  { name: "Japan Standard Time (JST)", value: "Asia/Tokyo" },
];
@UntilDestroy()
@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrl: './user-profile.component.scss'
})
export class UserProfileComponent implements OnInit {
  isEditing = false;
  oldPasswordVisible = false;
  newPasswordVisible = false;
  confirmPasswordVisible = false;
  apiCallLoader = { isSubmitLoading: false }
  profileForm!: FormGroup;
  passwordForm!: FormGroup;
  preferencesForm!: FormGroup;

  profileData = {
    firstName: '',
    lastName: '',
    email: '',
    contact: '',
    role: ''
  };

  timezones = timezones;
  constructor(
    private fb: FormBuilder,
    private message: NzMessageService,
    public commSvc: CommonService,
    public userService: UsersService,
    private authService: AuthService,
    private router: Router,
    public store: Store<AuthState>,
    protected _location: Location
  ) {
    this.profileData = {
      firstName: this.commSvc.user_data.first_name,
      lastName: this.commSvc.user_data.last_name,
      email: this.commSvc.user_data.email,
      contact: this.commSvc.user_data.contact,
      role: this.commSvc.user_data.userRoles.role_name
    };
  }

  ngOnInit() {
    this.initializeForms();
  }

  initializeForms() {
    this.profileForm = this.fb.group({
      user_id: [this.commSvc.user_data.user_id],
      first_name: [this.profileData.firstName, [Validators.required]],
      last_name: [this.profileData.lastName, [Validators.required]]
    });

    this.passwordForm = this.fb.group({
      email: [this.commSvc.user_data.email],
      oldPassword: ['', [Validators.required, Validators.maxLength(10)]],
      newPassword: ['', [Validators.required, Validators.maxLength(10), Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]+$')]],
      confirmPassword: ['', [Validators.required]]
    }, { validators: [this.passwordMatchValidator, this.oldNewPasswordValidator]});
    this.passwordForm.get('oldPassword')?.valueChanges.subscribe(() => {
      this.passwordForm.get('newPassword')?.updateValueAndValidity();
    });
    this.passwordForm.get('newPassword')?.valueChanges.subscribe(() => {
      const confirmPasswordControl = this.passwordForm.get('confirmPassword');
      if (confirmPasswordControl?.touched) { 
        confirmPasswordControl.updateValueAndValidity();
      }
    });

    this.preferencesForm = this.fb.group({
      user_id: [this.commSvc.user_data.user_id],
      tempPreference: [this.commSvc.temp_pref, Validators.required],
      timezone: [this.commSvc.time_zone, Validators.required]
    });
  }

  passwordMatchValidator(g: FormGroup): void {
    const newPassword = g.get('newPassword');
    const confirmPassword = g.get('confirmPassword');
    if (!newPassword || !confirmPassword) return;
    if (confirmPassword.touched) {
      if (newPassword.value !== confirmPassword.value) {
        confirmPassword.setErrors({ passwordMismatch: true });  // ✅ Set error on confirmPassword
      } else {
        confirmPassword.setErrors(null);  // ✅ Clear error when matching
      }
    }
  }
  oldNewPasswordValidator(g: FormGroup): void {
    const oldPassword = g.get('oldPassword');
    const newPassword = g.get('newPassword');
    if (!oldPassword || !newPassword) return;
    if (oldPassword.value && newPassword.value) {
      if (oldPassword.value === newPassword.value) {
        newPassword.setErrors({ sameAsOld: true });  // Error if exactly the same
      } else {
        const currentErrors = newPassword.errors;
        if (currentErrors) {
          delete currentErrors['sameAsOld'];
          if (Object.keys(currentErrors).length === 0) {
            newPassword.setErrors(null);  // Clear errors if none left
          } else {
            newPassword.setErrors(currentErrors);
          }
        }
      }
    }
  }
  
  onProfileSubmit(): void {
    if (this.profileForm.invalid) {
      this.profileForm.markAllAsTouched(); // Shows validation messages on submit
      return;
    }
    this.apiCallLoader.isSubmitLoading = true;
    this.userService.updateProfile(this.profileForm.value).pipe(untilDestroyed(this),
      finalize(() => (this.apiCallLoader.isSubmitLoading = false)))
      .subscribe({
        next: (response: any) => {
          if (response.status === 200) {
            this.message.success('Profile Updated.', { nzDuration: 5000 });
            this.store.dispatch(updateName({
              name: {
                first_name: this.profileForm.get('first_name')?.value,
                last_name: this.profileForm.get('last_name')?.value
              }
            })
            );
          }
        }
      });
  }
  onPasswordSubmit(): void {
    if (this.passwordForm.invalid) return;
    const requestData = {
      email: this.passwordForm.get('email')?.value,
      oldPassword: this.passwordForm.get('oldPassword')?.value,
      password: this.passwordForm.get('newPassword')?.value,
      change_type : 'profile'
    }
    this.apiCallLoader.isSubmitLoading = true
    this.authService.changePassword(requestData).pipe(untilDestroyed(this),
      finalize(() => (this.apiCallLoader.isSubmitLoading = false))).subscribe({
        next: (response: any) => {
          if (response.status === 200) {
            this.message.success('Password has been changed successfully.', { nzDuration: 5000 });
            this.router.navigate(['/auth/login']);
          }
        }
      });
  }
  onPreferencesSubmit() {
    if (!this.preferencesForm.valid) return
    this.apiCallLoader.isSubmitLoading = true
    this.userService.updateSettings(this.preferencesForm.value).pipe(untilDestroyed(this),
      finalize(() => (this.apiCallLoader.isSubmitLoading = false))).subscribe({
        next: (response: any) => {
          if (response['status'] === 200) {
            this.message.success('Settings updated successfully!', { nzDuration: 5000 });
            this.store.dispatch(updatePref({
              preference: {
                temp_preference: this.preferencesForm.get('tempPreference')?.value,
                timezone: this.preferencesForm.get('timezone')?.value
              }
            }));
          }
        }
      });
  }

}
