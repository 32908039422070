import { timezones } from './user-profile/user-profile.component';
import { Injectable } from '@angular/core';
import { CommonService } from '../../shared/services/common.service';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { API_ENDPOINTS } from '../../core/services/api.service';
import { Ticket } from './get-support/get-support.component';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})

export class TicketServiceService {

  constructor(private commonSvc: CommonService, private http: HttpClient ) { }

  addSupportTicket(formData: any): Observable<any> {
    return this.commonSvc.httpSvc.postFile<Ticket[]>(environment.apiBaseUrl + API_ENDPOINTS.PROFILE.ADD_SUPPORT_TICKET ,formData)
    //return this.http.post('http://localhost:3000/api/support/addSupportTicket', formData)
  }
  fetchRecentTicket(payload: {customer_id: number, location_id: any, timezone: string}): Observable<any> {
    return this.commonSvc.httpSvc.post<any>(environment.apiBaseUrl + API_ENDPOINTS.PROFILE.GET_RECENT_TICKET, payload)
    //return this.http.post('http://localhost:3000/api/support/getSupportTicket', payload)
  }
  getDemoVideo(): Observable<any> {
    return this.commonSvc.httpSvc.get<any>(environment.apiBaseUrl + API_ENDPOINTS.PROFILE.DEMO_VIDEOS)
  }
}
