<nz-card>
  <nz-page-header (nzBack)="_location.back()" nzBackIcon nzTitle="Resources" class="pb-4 pt-0 px-0"></nz-page-header>
  <nz-table #resourcesTable [nzLoading]="isTableLoading" [nzShowPagination]="false" [nzSize]="'small'"
    [nzPageSize]="listOfData.length" [nzData]="listOfData" [nzScroll]="{y:'600px', x:'800px'}" class="w-full">
    <thead>
      <tr>
        <th nzWidth="1rem">Sr.</th>
        <th nzWidth="8rem">Title</th>
        <th nzWidth="8rem">Description</th>
        <th nzWidth="2rem">View Video</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of resourcesTable.data index as i">
        <td>{{ i + 1 }}</td>
        <td nzEllipsis="...">{{ data.title}}</td>
        <td nzEllipsis="...">{{ data.description}}</td>
        <td>
          <button nz-button nzType="link" nzSize="small" (click)="viewTicket(data, tplContent)" class="text-blue-500 underline">
            <i nz-icon nzType="video-camera" nzTheme="outline"></i>
            View
          </button>
        </td>
      </tr>
    </tbody>
  </nz-table>
</nz-card>

<ng-template #tplContent let-params>
  <div class="text-center">
    <ng-container *ngIf="resources.fileLocation">
      <video [src]="resources.fileLocation" controls controlsList="nodownload" oncontextmenu="return false;"></video>
    </ng-container>
  </div>
</ng-template>