<div class="container mx-auto">
  <!-- Profile Header -->
  <div class="bg-white rounded-lg shadow mb-3">
    <div class="px-6 py-4 flex justify-between items-center">
      <h2 class="text-xl font-semibold"><button class="text-gray-600 hover:text-gray-800 mr-3"
          (click)="_location.back()">
          <i nz-icon nzType="arrow-left" nzTheme="outline"></i>
        </button>My Profile</h2>

    </div>
  </div>

  <!-- Basic Details -->
  <div class="bg-white rounded-lg shadow mb-3">
    <div class="p-6">
      <div class="flex flex-col sm:flex-row">
        <!-- Left Column - Avatar and Role -->
        <div class="w-full sm:w-1/6 flex flex-col items-center mb-4 sm:mb-0">
          <h6 class="text-sm text-gray-600 mb-4">Basic Details</h6>
          <div class="w-20 h-20 bg-primary rounded-full flex items-center justify-center text-white mb-2">
            <i nz-icon nzType="user" nzTheme="outline" class="text-2xl"></i>
          </div>
          <p class="text-sm text-gray-600">{{profileData.role}}</p>
        </div>

        <!-- Right Column - Form -->
        <div class="w-full sm:w-5/6">
          <div class="flex justify-end mb-4">
            <button (click)=" this.isEditing = !this.isEditing;" class="text-primary hover:text-blue-700">
              <i nz-icon [nzType]="isEditing ? 'close' : 'edit'" nzTheme="outline"></i>
            </button>
          </div>

          <form [formGroup]="profileForm" (ngSubmit)="onProfileSubmit()" *ngIf="isEditing">
            <div class="grid grid-cols-2 sm:grid-cols-2 gap-2 sm:gap-2">
              <!-- First Name -->
              <div class="col-span-2 sm:col-span-1">
                <label class="block text-sm font-medium text-gray-700 mb-2">First Name</label>
                <nz-form-item>
                  <nz-form-control [nzErrorTip]="firstNameError">
                    <input nz-input formControlName="first_name" class="w-full" />
                  </nz-form-control>
                </nz-form-item>
                <ng-template #firstNameError>
                  <span *ngIf="profileForm.get('first_name')?.hasError('required')">First name is required.</span>
                  <span *ngIf="profileForm.get('first_name')?.hasError('minlength')">Minimum 2 characters
                    required.</span>
                </ng-template>
              </div>

              <!-- Last Name -->
              <div class="col-span-2 sm:col-span-1">
                <label class="block text-sm font-medium text-gray-700 mb-2">Last Name</label>
                <nz-form-item>
                  <nz-form-control [nzErrorTip]="lastNameError">
                    <input nz-input formControlName="last_name" class="w-full" />
                  </nz-form-control>
                </nz-form-item>
                <ng-template #lastNameError>
                  <span *ngIf="profileForm.get('last_name')?.hasError('required')">Last name is required.</span>
                  <span *ngIf="profileForm.get('last_name')?.hasError('minlength')">Minimum 2 characters
                    required.</span>
                </ng-template>
              </div>

              <!-- Email (Readonly) -->
              <div class="col-span-2 sm:col-span-1">
                <label class="block text-sm font-medium text-gray-700 mb-2">Email</label>
                <nz-form-item>
                  <nz-form-control>
                    <input nz-input [value]="profileData.email" class="w-full" readonly />
                  </nz-form-control>
                </nz-form-item>
              </div>

              <!-- Contact (Readonly) -->
              <div class="col-span-2 sm:col-span-1">
                <label class="block text-sm font-medium text-gray-700 mb-2">Contact</label>
                <nz-form-item>
                  <nz-form-control>
                    <input nz-input [value]="profileData.contact" class="w-full" readonly />
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>

            <!-- Submit Button -->
            <div class="flex justify-end mt-4">
              <button nz-button nzType="primary" [disabled]="profileForm.invalid || profileForm.pristine"
                type="submit">Update</button>
            </div>
          </form>


          <div *ngIf="!isEditing">
            <h2 class="text-2xl text-primary mb-4">{{profileData.firstName}} {{profileData.lastName}}</h2>
            <p class="mb-2">
              <i nz-icon nzType="mail" class="mr-2 text-primary"></i>
              <strong class="text-primary">Email:</strong> {{profileData.email}}
            </p>
            <p>
              <i nz-icon nzType="phone" class="mr-2 text-primary"></i>
              <strong class="text-primary">Contact:</strong> {{profileData.contact}}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Change Password -->
  <div class="bg-white rounded-lg shadow mb-3">
    <div class="p-6">
      <div class="flex flex-col sm:flex-row">
        <div class="w-full sm:w-1/6 flex flex-col items-center mb-4 sm:mb-0">
          <h6 class="text-sm text-gray-600 mb-4">Change Password</h6>
          <div class="w-20 h-20 bg-primary rounded-full flex items-center justify-center text-white">
            <i nz-icon nzType="lock" nzTheme="outline" class="text-2xl"></i>
          </div>
        </div>

        <div class="w-full sm:5/6">
          <form [formGroup]="passwordForm" (ngSubmit)="onPasswordSubmit()">
            <div class="grid grid-cols-3 sm:grid-cols-3 sm:gap-6 gap-2">
              <div class="col-span-3 sm:col-span-1">
                <nz-form-item>
                  <nz-form-control [nzErrorTip]="oldPasswordError">
                    <label class="block text-sm font-medium text-gray-700 mb-2">Old Password</label>
                    <nz-input-group [nzSuffix]="oldPasswordSuffix">
                      <input [type]="oldPasswordVisible ? 'text' : 'password'" nz-input formControlName="oldPassword"
                        [maxLength]="10" appNoSpaces="nospace" placeholder="Enter old password" />
                    </nz-input-group>
                  </nz-form-control>
                  <ng-template #oldPasswordSuffix>
                    <i nz-icon [nzType]="oldPasswordVisible ? 'eye' : 'eye-invisible'"
                      (click)="oldPasswordVisible = !oldPasswordVisible">
                    </i>
                  </ng-template>
                  <ng-template #oldPasswordError>
                    <ng-container
                      *ngIf="passwordForm.get('oldPassword')?.hasError('required') && (passwordForm.get('oldPassword')?.touched)">
                      <span class="text-xsm">Old Password is required.</span>
                    </ng-container>
                  </ng-template>
                </nz-form-item>
              </div>
              <div></div>
              <div></div>
              <div class="col-span-3 sm:col-span-1">
                <nz-form-item>
                  <nz-form-control [nzErrorTip]="newPasswordError">
                    <label class="block text-sm font-medium text-gray-700 mb-2">New Password</label>
                    <nz-input-group [nzSuffix]="newPasswordSuffix">
                      <input [type]="newPasswordVisible ? 'text' : 'password'" nz-input formControlName="newPassword"
                        [maxLength]="10" appNoSpaces="nospace" placeholder="Enter new password" />
                    </nz-input-group>
                  </nz-form-control>
                  <ng-template #newPasswordSuffix>
                    <i nz-icon [nzType]="newPasswordVisible ? 'eye' : 'eye-invisible'"
                      (click)="newPasswordVisible = !newPasswordVisible">
                    </i>
                  </ng-template>
                  <ng-template #newPasswordError>
                    <ng-container *ngIf="passwordForm.get('newPassword')?.touched">
                      <span *ngIf="passwordForm.get('newPassword')?.hasError('required')" class="text-xsm text-red-500">
                        New Password is required.
                      </span>
                      <span *ngIf="passwordForm.get('newPassword')?.hasError('pattern')" class="text-xsm text-red-500">
                        Password must contain at least one uppercase letter, one lowercase letter, one number and one
                        special character.
                      </span>
                      <span *ngIf="passwordForm.get('newPassword')?.hasError('minlength')" class="text-xsm text-red-500">
                        Minimum 10 characters required.
                      </span>
                      <span *ngIf="passwordForm.get('newPassword')?.hasError('sameAsOld')" class="text-xsm text-red-500">
                        New Password cannot be the same as the Old Password.
                      </span>
                    </ng-container>
                  </ng-template>
                </nz-form-item>
              </div>

              <div class="col-span-3 sm:col-span-1">
                <nz-form-item>
                  <nz-form-control [nzErrorTip]="confirmPasswordError">
                    <label class="block text-sm font-medium text-gray-700 mb-2">Confirm Password</label>
                    <nz-input-group [nzSuffix]="confirmPasswordSuffix">
                      <input [type]="confirmPasswordVisible ? 'text' : 'password'" nz-input [maxLength]="10"
                        appNoSpaces="nospace" formControlName="confirmPassword" placeholder="Confirm new password"
                        (blur)="passwordForm.get('confirmPassword')?.updateValueAndValidity()"/>
                    </nz-input-group>
                  </nz-form-control>
                  <ng-template #confirmPasswordSuffix>
                    <i nz-icon [nzType]="confirmPasswordVisible ? 'eye' : 'eye-invisible'"
                      (click)="confirmPasswordVisible = !confirmPasswordVisible">
                    </i>
                  </ng-template>
                  <ng-template #confirmPasswordError>
                    <ng-container *ngIf="passwordForm.get('confirmPassword')?.touched">
                      <span *ngIf="passwordForm.get('confirmPassword')?.hasError('required')" class="text-xsm text-red-500">
                        Confirm Password is required.
                      </span>
                      <span *ngIf="passwordForm.get('confirmPassword')?.hasError('pattern')" class="text-xsm text-red-500">
                        Password must contain at least one uppercase letter, one lowercase letter, one number and one
                        special character.
                      </span>
                      <span *ngIf="passwordForm.get('confirmPassword')?.hasError('minlength')" class="text-xsm text-red-500">
                        Minimum 10 characters required.
                      </span>
                      <span *ngIf="passwordForm.get('confirmPassword')?.hasError('passwordMismatch')" class="text-xsm text-red-500">
                        Passwords do not match.
                      </span>
                    </ng-container>
                  </ng-template>
                </nz-form-item>
              </div>

              <div></div>
              <div class="col-span-3 flex justify-end">
                <button [disabled]="passwordForm.invalid || passwordForm.pristine" nz-button nzType="primary" type="submit">Change Password</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <!-- Preferences -->
  <div class="bg-white rounded-lg shadow">
    <div class="p-6">
      <div class="flex flex-col sm:flex-row">
        <div class="w-full sm:w-1/6 flex flex-col items-center mb-4 sm:mb-0">
          <h6 class="text-sm text-gray-600 mb-4">Preferences</h6>
          <div class="w-20 h-20 bg-primary rounded-full flex items-center justify-center text-white">
            <i nz-icon nzType="setting" nzTheme="outline" class="text-2xl"></i>
          </div>
        </div>

        <div class="w-full sm:w-5/6">
          <form [formGroup]="preferencesForm" (ngSubmit)="onPreferencesSubmit()">
            <div class="grid grid-cols-1 sm:grid-cols-2 gap-6">
              <div class="col-span-2 sm:col-span-1">
                <label class="block text-sm font-medium text-gray-700 mb-2">Temperature Preference</label>
                <nz-select formControlName="tempPreference" class="w-full">
                  <nz-option nzValue="D" nzLabel="Degree Celsius"></nz-option>
                  <nz-option nzValue="F" nzLabel="Fahrenheit"></nz-option>
                </nz-select>
              </div>
              <div class="col-span-2 sm:col-span-1">
                <label class="block text-sm font-medium text-gray-700 mb-2">Time Zone Preference</label>
                <nz-select formControlName="timezone" class="w-full">
                  <nz-option *ngFor="let tz of timezones" [nzValue]="tz.value" [nzLabel]="tz.name">
                  </nz-option>
                </nz-select>
              </div>
            </div>
            <div class="flex justify-end mt-4">
              <button nz-button nzType="primary" type="submit">Submit</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>