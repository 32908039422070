<div class="flex desktop:flex-nowrap flex-wrap justify-between gap-x-4 h-[90dvh] max-h-[90dvh]"
  [ngClass]="auth.isAuthenticated() ? 'mt-4' : 'mt-4 mx-4'">
  <!-- * Support Tickets Forms -->
  <nz-card class="desktop-lg:min-w-8/12 mini:h-fit desktop-lg:h-fit desktop:h-fit w-full"
    [ngClass]="auth.isAuthenticated() ? 'min-h-full' : ''">
    <nz-page-header (nzBack)="_location.back()" nzBackIcon nzTitle="Raise a support ticket"
      class="py-0">
      <nz-page-header-extra>
        <nz-space>
          <button nz-button *ngIf="auth.isAuthenticated()" (click)="trainingVideos()">View Resources</button>
        </nz-space>
      </nz-page-header-extra>
    </nz-page-header>
    <nz-divider class="mt-4 mb-4"></nz-divider>
    <div class="p-6">
      <div class="flex flex-col sm:flex-row">
        <!-- Left Column - Icon -->
        <div class="w-full sm:w-1/6 flex flex-col items-center mb-6 sm:mb-0 px-3">
          <h6 class="text-sm text-gray-600 mb-4 text-center sm:text-left">Create Ticket</h6>
          <div
            class="w-16 h-16 sm:w-20 sm:h-20 bg-primary rounded-full flex items-center justify-center text-white mb-2">
            <i nz-icon nzType="form" nzTheme="outline" class="text-xl sm:text-2xl"></i>
          </div>
        </div>
        <div class="w-full px-3">
          <form [formGroup]="ticketForm" nz-form nzLayout="vertical" (ngSubmit)="onSubmitTicket()">
            <div nz-row [nzGutter]="[14, 0]">
              <ng-container *ngIf="!auth.isAuthenticated()">
                <div nz-col class="gutter-row" nzXs="24" nzMd="11" nzLg="10" nzXl="10">
                  <nz-form-item>
                    <nz-form-label nzRequired>Customer ID</nz-form-label>
                    <nz-form-control [nzErrorTip]="assetError">
                      <input nz-input OnlyNumber formControlName="customer_id" maxlength="5"
                        placeholder="Enter Customer ID" />
                    </nz-form-control>
                    <ng-template #assetError let-control>
                      @if (control.errors?.['required']) { Customer ID is Required! }
                    </ng-template>
                  </nz-form-item>
                </div>
              </ng-container>
              <ng-container *ngIf="!auth.isAuthenticated()">
                <div nz-col class="gutter-row" nzXs="24" nzMd="17" nzLg="15" nzXl="10">
                  <nz-form-item>
                    <nz-form-label nzRequired>Username</nz-form-label>
                    <nz-form-control [nzErrorTip]="assetError">
                      <input nz-input formControlName="user_name" maxlength="50" placeholder="Enter First and Last Name" />
                    </nz-form-control>
                    <ng-template #assetError let-control>
                      @if (control.errors?.['required']) { Username is Required! }
                    </ng-template>
                  </nz-form-item>
                </div>
              </ng-container>
              <ng-container *ngIf="!auth.isAuthenticated()">
                <div nz-col class="gutter-row" nzXs="24" nzMd="13" nzLg="13" nzXl="10">
                  <nz-form-item>
                    <nz-form-label nzRequired>Email</nz-form-label>
                    <nz-form-control [nzErrorTip]="assetError">
                      <input nz-input formControlName="email" maxlength="100" placeholder="Enter Email ID" />
                    </nz-form-control>
                    <ng-template #assetError let-control>
                      @if (control.errors?.['required']) { Email ID is Required! }
                      @if (control.errors?.['email']) { Invalid Email ID }
                    </ng-template>
                  </nz-form-item>
                </div>
              </ng-container>
              <ng-container *ngIf="!auth.isAuthenticated()">
                <div nz-col class="gutter-row" nzXs="24" nzMd="17" nzLg="15" nzXl="10">
                  <nz-form-item>
                    <nz-form-label nzRequired>Subject</nz-form-label>
                    <nz-form-control [nzErrorTip]="assetError">
                      <input nz-input formControlName="subject" maxlength="50" placeholder="Enter Subject" />
                    </nz-form-control>
                    <ng-template #assetError let-control>
                      @if (control.errors?.['required']) { Subject is Required! }
                    </ng-template>
                  </nz-form-item>
                </div>
              </ng-container>
              <ng-container *ngIf="auth.isAuthenticated()">
                <div nz-col class="gutter-row" nzXs="24" nzMd="17" nzLg="15" nzXl="10">
                  <nz-form-item>
                    <nz-form-label nzRequired>Location</nz-form-label>
                    <nz-form-control [nzErrorTip]="assetError">
                      <nz-tree-select [nzStatus]="value.length === 0 && isLocationError ? 'error' : ''" class="w-full"
                        [nzDefaultExpandAll]="true" [nzAllowClear]="false" [nzMaxTagCount]="8"
                        [nzDropdownStyle]="{ maxHeight: '300px', overflow: 'auto'}" [nzHideUnMatched]="true"
                        [ngModelOptions]="{standalone: true}" [(ngModel)]="value" #tree [nzNodes]="nodes || []"
                        (ngModelChange)="onLocationChange($event)"
                        nzShowSearch
                        nzPlaceHolder="Please select location">
                    </nz-tree-select>
                    </nz-form-control>
                    <ng-template #assetError let-control>
                      @if (control.errors?.['required']) { Location is Required! }
                    </ng-template>
                  </nz-form-item>
                </div>
              </ng-container>
              <ng-container *ngIf="auth.isAuthenticated()">
                <div nz-col class="gutter-row" nzXs="24" nzMd="17" nzLg="15" nzXl="10">
                  <nz-form-item>
                    <nz-form-label nzRequired>Subject</nz-form-label>
                    <nz-form-control [nzErrorTip]="assetError">
                      <input nz-input formControlName="user_subject" maxlength="50" placeholder="Enter Subject" />
                    </nz-form-control>
                    <ng-template #assetError let-control>
                      @if (control.errors?.['required']) { Subject is Required! }
                    </ng-template>
                  </nz-form-item>
                </div>
              </ng-container>
              <ng-container class="col-span-3" *ngIf="!auth.isAuthenticated()">
                <div nz-col class="gutter-row" nzXs="24" nzMd="24" nzLg="24" nzXl="20">
                  <nz-form-item>
                    <nz-form-label nzRequired>Description</nz-form-label>
                    <nz-form-control [nzErrorTip]="assetError">
                      <nz-textarea-count [nzMaxCharacterCount]="500">
                        <textarea maxlength="500" rows="3" nz-input formControlName="description"
                          placeholder="Enter ticket description" class="w-full"></textarea>
                      </nz-textarea-count>
                    </nz-form-control>
                    <ng-template #assetError let-control>
                      @if (control.errors?.['required']) { Description is Required! }
                    </ng-template>
                  </nz-form-item>
                </div>
              </ng-container>
              <ng-container class="col-span-3" *ngIf="auth.isAuthenticated()">
                <div nz-col class="gutter-row" nzXs="24" nzMd="24" nzLg="24" nzXl="20">
                  <nz-form-item>
                    <nz-form-label nzRequired>Description</nz-form-label>
                    <nz-form-control [nzErrorTip]="assetError">
                      <nz-textarea-count [nzMaxCharacterCount]="500">
                        <textarea maxlength="500" rows="3" nz-input formControlName="user_description"
                          placeholder="Enter ticket description" class="w-full"></textarea>
                      </nz-textarea-count>
                    </nz-form-control>
                    <ng-template #assetError let-control>
                      @if (control.errors?.['required']) { Description is Required! }
                    </ng-template>
                  </nz-form-item>
                </div>
              </ng-container>
              <ng-container class="col-span-3" *ngIf="auth.isAuthenticated()">
                <div nz-col class="gutter-row" nzXs="24" nzMd="24" nzLg="24" nzXl="20">
                  <label class="block text-sm font-medium text-gray-700">Attachments</label>
                  <div class="upload-container" [class.hidden]="fileList.length >= maxFiles">
                    <label for="attachmentFile" class="custom-file-upload">
                      <i nz-icon nzType="upload" nzTheme="outline" class="text-[30px] text-primary"></i>
                      <input #supportFile type="file" id="files" (change)="onFileChange($event)"
                        accept=".pdf,.jpeg,.jpg,.png,.mp4" multiple />
                      <p class="ant-upload-text">Click or drag files to upload</p>
                      <p class="ant-upload-hint"> Support for single or bulk upload. Max 3 files, 5MB each. </p>
                    </label>
                  </div>
                  <div class="uploaded-files" *ngIf="fileList.length > 0">
                    <div *ngFor="let file of fileList; let i = index"
                      class="uploaded-file flex items-center justify-between mb-2">
                      <i nz-icon [nzType]="file.name.toLowerCase().endsWith('.pdf') ? 'file-pdf' : 'file-image'"
                        nzTheme="twotone" class="mr-2"></i>
                      <span class="filename flex-1">{{ file?.name }}</span>
                      <span class="filesize text-sm text-gray-600">
                        ({{ (file.size / 1024 / 1024).toFixed(2) }} MB)
                      </span>
                      <button type="button" nzType="link" (click)="removeFile(i)">
                        <i nz-icon nzType="delete"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
            <div class="flex justify-end mt-2">
              <button nz-button type="button" nzType="default" class="mr-4" (click)="resetForm()">Reset</button>
              <button nz-button type="submit" nzType="primary" [disabled]="!ticketForm.valid" [nzLoading]="isLoading"
                type="submit">
                Submit Ticket
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </nz-card>
  <!-- * Recent Tickets -->
  <nz-card
    class="desktop-lg:w-4/12 desktop:w-5/12 desktop:mt-0 mini:mt-4 mini:w-full mini:h-fit desktop-lg:h-full desktop:h-fit"
    *ngIf="auth.isAuthenticated()">
    <nz-page-header nzTitle="Recent Tickets" class="py-0"></nz-page-header>
    <nz-divider class="mt-4 mb-4"></nz-divider>
    <div class="w-full sm:w-6/6 px-4">
      <nz-radio-group class="mb-3" nzButtonStyle="outline" [ngModel]="quickFilter"
        (ngModelChange)="quickFilterChange($event)">
        <label *ngFor="let filter of filters" nz-radio-button [nzValue]="filter.value" [class]="filter.class">
          <i *ngIf="filter.icon" nz-icon [nzType]="filter.icon" [nzTheme]="filter.theme || 'outline'" class="mr-1"></i>
          <span *ngIf="filter.customIcon" class="mr-2" style="font-size: 14px !important" nz-icon
            [nzType]="filter.customIcon"></span>
          {{filter.label}} {{filter.count}}
        </label>
      </nz-radio-group>
      <nz-table #ticketTable [nzLoading]="isTableLoading" [nzShowPagination]="false" [nzPageSize]="ticketList.length"
        [nzSize]="'small'" [nzData]="ticketList" [nzScroll]="{y:'480px', x:'400px'}" class="w-full">
        <thead>
          <tr>
            <th nzWidth="60px">Ticket ID</th>
            <th nzWidth="100px">Create Date</th>
            <th nzWidth="80px">Status</th>
            <th nzWidth="90px" nzRight>View Details</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let ticket of ticketTable.data">
            <td>{{ ticket.ticket_id }}</td>
            <td>{{ ticket.createDate | date: 'dd-MM-yyyy' }}</td>
            <td>
              <nz-tag [nzColor]="getStatusColor(ticket.status)">
                {{ ticket.status }}
              </nz-tag>
            </td>
            <td><a role="button" (click)="viewTicket(ticket)" class="text-blue-500 underline">View</a> </td>
          </tr>
        </tbody>
      </nz-table>
    </div>
  </nz-card>
</div>
<ng-template #viewTicketModal>
  <span class="font-medium">Subject:</span>
  <p>{{ticketById.subject}}</p>
  <span class="font-medium">Description:</span>
  <p>
    {{ isExpanded ? ticketById.description : (ticketById.description | slice:0:100)}}
    <ng-container *ngIf="isExpanded"></ng-container>
    <a class="text-primary" *ngIf="ticketById.description.length > 100" (click)="toggleText()">
      {{ isExpanded ? 'Read Less' : '...Read More' }}
    </a>
  </p>
  <ng-container *ngFor="let file of parsedFileLocations; let i = index">
    <div class="flex items-center justify-start gap-x-1">
      <p *ngIf="parsedFileLocations.length > 1">{{ i + 1 }}.</p>
      <button nz-button nzType="link" class="text-primary px-0" (click)="openAttachment(file)">
        Download Attachment
        <i nz-icon nzType="download" nzTheme="outline"></i>
      </button>
    </div>
  </ng-container>
</ng-template>
